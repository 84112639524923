import React from 'react'
import availableLangs from '@localization/availableLangs'
import SeoContainer from '@containers/SeoContainer'
import TopInfluencesPageContainer from '../../components/containers/pagesContainers/TopInfluencesPageContainer'

const TopInfluencesPage = () => {
  return (
    <>
      <SeoContainer pageLang={availableLangs.de} pagePath='top' />
      <TopInfluencesPageContainer />
    </>
  )
}

export default TopInfluencesPage
